import $ from 'jquery';
import axios from 'axios';

const form = (() => {
  const config = {
    trigger: '.js-formTrigger',
    errorCN: 'js-formError',
    message: {
      success: `<div class="js-formMessage" style="display: none;">
                  <p class="abstract">
                    <b>Toppen – nu har du helkoll</b>.<br>
                    Nu får du senaste nytt om Kista Parkstad direkt i din mejl. Vi hörs!
                  </p>
                  <button class="button -primary js-lightboxDismiss">Okej</button>
                </div>`,
      fail: `<ul class='form__error js-formError'>
              <li>Det verkar som att du missat att fylla i en ruta.</li>
            </ul>`,
    },
  };

  const sanitize = input => DOMPurify.sanitize(
    $(input).val().trim(),
    { ALLOWED_TAGS: false,
      SAFE_FOR_TEMPLATES: true,
    },
  );

  const validate = (inputs) => {
    const err = [];
    const [first, last, email] = inputs;

    if (first === '') {
      err.push('Please, check the first name field');
    }
    if (last === '') {
      err.push('Please, check the last name field');
    }
    if (email === '') {
      err.push('Please, check the email field');
    }
    return err;
  };

  const hasErrors = ({ errors: err, f }) => {
    let answer;

    // remove error markup if it already exists
    if ($(`.${config.errorCN}`).length > 0) {
      $(`.${config.errorCN}`).remove();
    }

    if (err.length > 0) {
      // create HTML markup for errors
      const errorTemplate = `<ul class='form__error ${config.errorCN}'>
                              ${err.map(s => `<li>${s}</li>`).join('')}
                             </ul>`;
      // add error list to DOM
      $(f).prepend(errorTemplate);
      // report errors
      answer = true;
    } else {
      // no errors
      answer = false;
    }
    return answer;
  };

  const handleSubmit = ({ t: f }) => {
    const $form = $(f);
    const inputs = $form.find('input');
    const cleanInputs = [];

    // prevent form from submission
    event.preventDefault();

    // clean inputs from malicious things
    inputs.each(function () {
      cleanInputs.push(sanitize(this));
    });

    // check for errors in every input
    const errors = validate(cleanInputs);

    if (!hasErrors({ errors, f })) {
      const url = $form.attr('action');
      const type = $form.attr('method');
      const nonce = $form.find('#_wpnonce').val();

      $.ajax({
        url,
        type,
        data: {
          firstname: cleanInputs[0],
          lastname: cleanInputs[1],
          email: cleanInputs[2],
          nonce,
        },
        beforeSend: () => {
          $form.fadeTo(200, 0.3);
          $form.css('pointers-event', 'none');
        },
        success: (data) => {
          if (data) {
            $('.lightbox__content').height($('.lightbox__content').height());

            $(config.message.success).insertBefore($form);

            $form.fadeOut(200, () => {
              $('.js-formMessage').fadeIn(200);
              $form.remove();
            });
          } else {
            // add error list to DOM
            $form.prepend(config.message.error);
            // show the form again
            $form.css('pointers-event', 'inherit');
            $form.fadeTo(200, 1);
          }
        },
        fail: (e) => {
          console.log(e);
          console.log('fail');
        },
      });
    }
  };

  const build = (trigger) => {
    $(trigger).on('submit', function () {
      handleSubmit({ t: this });
    });
  };

  const setup = () => {
    $(config.trigger).each((i, trigger) => build(trigger));
  };

  const init = () => {
    setup();
  };

  return {
    init };
})();

export default form.init();

import $ from 'jquery';

$.fn.nearest = function (selector) {
  let self;
  let nearest;
  let el;
  let s;
  let p;
  const hasQsa = document.querySelectorAll;

  const update = (ele) => {
    nearest = nearest ? nearest.add(ele) : $(ele);
  };

  this.each(function () {
    self = this;

    $.each(selector.split(','), function () {
      s = $.trim(this);

      if (!s.indexOf('#')) {
        // selector starts with an ID
        update((hasQsa ? document.querySelectorAll(s) : $(s)));
      } else {
        // is a class or tag selector
        // so need to traverse
        p = self.parentNode;
        while (p) {
          el = hasQsa ? p.querySelectorAll(s) : $(p).find(s);
          if (el.length) {
            update(el);
            break;
          }
          p = p.parentNode;
        }
      }
    });
  });

  return nearest || $();
};

$.fn.outer = function () {
  return $($('<div></div>').html(this.clone())).html();
};

import $ from 'jquery';
import '../utils/jq-plugins';

const loadMore = (() => {
  const config = {
    trigger: '.js-loadMoreTrigger',
    target: '.js-loadMoreTarget',
    perPage: 9,
    loadMoreText: 'Ladda fler',
    loadingText: 'Laddar...',
  };

  const disableTrigger = (trigger) => {
    const $trigger = $(trigger);
    // remove handler
    $trigger.remove();
  };

  const renderTemplate = (data) => {
    // loop through posts, prep the HTML and add it to the DOM
    const output = [];
    data.forEach((obj) => {
      const html = `<li class="listPosts__item grid__column -portrait-6 -ipad-4 js-loadMoreTemplate">
                      <a href="${obj.link}" class="listPosts__link block__color -hover">
                        <div class="listPosts__img" style="background-image: url('${obj.img}');"></div>
                        <div class="listPosts__content">
                          <h3 class="title -h2">${obj.title.rendered}</h3>
                          <div class="listPosts__text">
                            ${obj.excerpt.rendered}
                          </div>
                        </div>
                      </a>
                    </li>`;
      output.push(html);
    });
    return output.join('');
  };

  const handleLoad = (trigger) => {
    const $trigger = $(trigger);
    const $target = $trigger.nearest(config.target);
    const children = $target.children().length;
    const url = `${$trigger.data('url')}?offset=${children}&per_page=${config.perPage}`;

    $.ajax({
      url,
      type: 'GET',
      beforeSend: () => {
        $trigger.text(config.loadingText);
      },
      success: (data) => {
        console.log(data.length);
        // this give the next ${config.perPage} available posts
        if (data && data.length > 0) {
          // loop through posts, prep the HTML and add it to the DOM
          $target.append(renderTemplate(data));
          // update text button
          if (data.length < config.perPage) {
            // When there's no more data to fetch
            disableTrigger(trigger);
          } else {
            // Show me more baby
            $trigger.text(config.loadMoreText);
          }
        } else {
          disableTrigger(trigger);
        }
      },
    });
  };

  const build = (trigger) => {
    $(trigger).on('click.loadMore', () => handleLoad(trigger));
  };

  const setup = () => {
    $(config.trigger).each((i, trigger) => build(trigger));
  };

  const init = () => {
    setup();
  };

  return {
    init,
  };
})();

export default loadMore.init();

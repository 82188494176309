import $ from 'jquery';
import '../utils/jq-plugins';

const offset = (() => {
  const config = {
    trigger: '.js-offsetYTrigger',
    target: '.js-offsetYTarget',
    container: '.js-offsetYContainer',
    breakpoint: 768,
  };

  const handleOffset = (trigger) => {
    const ww = $(window).width();

    if (ww > config.breakpoint) {
      const $target = $(trigger).nearest(config.target);
      const $container = $target.nearest(config.container);
      let position = ($target.offset().top + $target.height()) - $container.outerHeight(true);

      // Firefox fix
      if (navigator.userAgent.indexOf('Firefox') > -1 || /MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
        position = ($target.children().last().offset().top + $target.children().last().height());
        position -= $container.outerHeight(true);
      }

      $(trigger).css('margin-top', position);
    } else {
      $(trigger).css('margin-top', '');
    }
  };

  const build = (trigger) => {
    handleOffset(trigger);

    $(window).on('resize.offset', () => { handleOffset(trigger); });
  };

  const setup = () => {
    $(config.trigger).each((i, trigger) => build(trigger));
  };

  const init = () => {
    setup();
  };

  return {
    init };
})();

export default offset.init();

import $ from 'jquery';
import '../utils/jq-plugins';
import { Cookie } from '../utils';

const cookieBox = (() => {
  const config = {
    dismiss: '.js-cookieDismiss',
    parent: '.js-cookieParent',
    cookieName: 'kista',
  };

  const handleCookie = () => {
    // hide cookie message
    $(config.dismiss).nearest(config.parent).fadeOut(200, () => $(config.parent).remove());

    // Set cookie example
    Cookie.set(config.cookieName, 'kista parkstad cookie consent', 1);
  };

  const setup = () => {
    $(config.parent).show();
    $(config.dismiss).on('click', () => handleCookie());
  };

  const init = () => {
    if (!Cookie.get(config.cookieName)) {
      setup();
    }
  };

  return {
    init };
})();

export default cookieBox.init();

import $ from 'jquery';

const visibleNavbar = () => {
  $(window).scroll(() => {
    const offset = $(window).scrollTop();
    $('.header.toggler').toggleClass('visible', offset > 800);
  });
};

export default visibleNavbar();

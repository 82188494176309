import $ from 'jquery';
import { freeze, handleESC } from '../utils/Utils';
import '../utils/jq-plugins';

const lightbox = (() => {
  const config = {
    trigger: '.js-lightboxTrigger',
    dismiss: '.js-lightboxDismiss',
    target: '.js-lightboxTarget',
  };

  const remove = ($target) => {
    freeze.stop();
    $target.fadeOut(200);
  };

  const handleHide = ($target) => {
    // hide when ESC is pressed
    handleESC.init('lightbox', () => {
      remove($target);
    });
    // hide when clicked on dark area
    $target.on('click', (e) => {
      // This helps the following code to be aplied to the parent, not the children
      if (e.target !== e.currentTarget) return;
      remove($target);
    });
  };

  const show = (trigger) => {
    const $target = $(trigger).nearest(config.target);
    // freeze html to prevent Scrolling
    freeze.start();
    // show lightbox
    $target.fadeIn(300);
    // handle remove
    handleHide($target);
  };

  const build = (trigger) => {
    $(trigger).on('click', () => show(trigger));
    // allow button after form submission to hide lightbox
    $('body').on('click', config.dismiss, function () {
      remove($(this).nearest(config.target));
    });
  };

  const setup = () => {
    $(config.trigger).each((i, trigger) => build(trigger));
  };

  const init = () => {
    setup();
  };

  return {
    init };
})();

export default lightbox.init();

import $ from 'jquery';

const navBtn = () => {
  $('.navBtn').on('click', () => {
    $('.nav__inner').toggleClass('clicked'); // adds the class clicked to nav__inner
    $('.nav').toggleClass('clicked'); // adds the class clicked to nav

    // changes the name of the menu button
    if ($('.navBtn').text() === 'Meny') {
      $('.navBtn').text('Stäng');
    } else {
      $('.navBtn').text('Meny');
    }
  });
};

export default navBtn();

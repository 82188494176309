import Flickity from 'flickity';

const flickity = (() => {
  const config = {
    get triggers() {
      return this.trigger
        .map(CN => [...document.querySelectorAll(`.${CN}`)])
        .reduce((acc, current) => [...acc, ...current]);
    },
    trigger: ['carousel'],
  };

  const build = () => {
    const carousels = document.querySelectorAll(`.${config.trigger}`);
    carousels.forEach((carousel) => {
      (() => new Flickity(carousel, {
        cellAlign: 'left',
        autoPlay: 3000,
        setGallerySize: false,
        prevNextButtons: false,
        lazyLoad: true,
        wrapAround: true,
      }))();
    });
  };
  const init = () => {
    if (config.triggers.length > 0) {
      build();
    }
  };
  return {
    init,
  };
})();

flickity.init();

import $ from 'jquery';

function stripTags(s) {
  return s.replace(/(<([^>]+)>)/ig, '');
}

const freeze = (() => {
  const freezeCN = 'freeze';
  const $html = $('html');

  const start = () => {
    $html.addClass(freezeCN);
  };
  const stop = () => {
    $html.removeClass(freezeCN);
  };

  return {
    start,
    stop };
})();

const handleESC = (() => {
  const init = (spacename, callback = false) => {
    $(document).on(`keyup.${spacename}`, (e) => {
      switch (e.which) { // ESC
        case 27:
          handleESC.stop(spacename);
          if (callback !== false) {
            callback();
          }
          break;
        default:
          break;
      }
    });
  };

  const stop = (spacename) => {
    $(document).off(`keyup.${spacename}`);
  };

  return {
    init,
    stop };
})();

export { stripTags, freeze, handleESC };

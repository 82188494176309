
const navItems = (() => {
  const config = {
    get triggers() {
      return this.headings
        .map(CN => [...document.querySelectorAll(`.${CN}`)])
        .reduce((acc, current) => [...acc, ...current]);
    },
    navBtn: ['navBtn'],
    nav: ['nav'],
    navInner: ['nav__inner'],
    navLinkClass: ['nav__link'],
    navClose: ['clicked'],
    headings: ['js-heading'],
  };

  const closeNav = () => {
    const navInner = document.querySelectorAll(`.${config.navInner}`);

    navInner.forEach((item) => {
      item.addEventListener('click', () => {
        if (item.classList.contains('clicked')) {
          item.classList.remove('clicked');
        }
      });
    });
  };

  const createNavItems = (headings) => {
    headings.forEach((heading) => {
      const nav = document.querySelector(`.${config.nav}`);
      const headingText = heading.innerText;
      const headingId = headingText.split(' ').join('-').toLowerCase();

      heading.setAttribute('id', headingId);

      const listItems = `
        <li class="nav__item">
          <a href="#${headingId}" class="${config.navLinkClass}">${headingText}</a>
        </li>
    `;
      nav.insertAdjacentHTML('beforeend', listItems);
    });
    closeNav();
  };

  const getAllArticleHeadings = () => {
    const headings = document.querySelectorAll(`.${config.headings}`);
    createNavItems(headings);
  };

  const smoothScroll = ((e) => {
    const navBtn = document.querySelector(`.${config.navBtn}`);

    if (navBtn.innerText === 'Stäng') {
      navBtn.innerHTML = 'Meny';
    }

    const anchorTarget = document.querySelector(e.target.getAttribute('href'));

    if (anchorTarget) {
      e.preventDefault();

      anchorTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  });


  const init = () => {
    if (config.triggers.length > 0) {
      getAllArticleHeadings();
    }

    const navLinks = document.querySelectorAll(`.${config.navLinkClass}`);
    navLinks.forEach((navLink) => {
      navLink.addEventListener('click', (e) => {
        smoothScroll(e);
      });
    });
  };

  return {
    init,
  };
})();

navItems.init();

import $ from 'jquery';
import { freeze, handleESC } from '../utils/Utils';
import '../utils/jq-plugins';

const video = (() => {
  const config = {
    trigger: '.js-videoTrigger',
    target: '.js-videoTarget',
    player: '.js-videoPlayer',
    template: `<section class="lightbox -video -show -loading js-videoTarget">
                <div class="loading">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="lightbox__content">
                  <iframe
                    style="display: none;"
                    class="js-videoPlayer"
                    src="{{url}}"
                    width="900"
                    height="506"
                    frameborder="0"
                    webkitallowfullscreen mozallowfullscreen allowfullscreen
                  ></iframe>
                </div>
               </section>`,
    loadingCN: '-loading',
  };

  const $body = $('body');

  const remove = ($target) => {
    freeze.stop();
    $target.fadeOut(200, () => {
      $target.remove();
    });
  };

  const handleHide = ($target) => {
    // hide when ESC is pressed
    handleESC.init('video', () => {
      remove($target);
    });
    // hide when clicked on dark area
    $target.on('click', (e) => {
      // This helps the following code to be aplied to the parent, not the children
      if (e.target !== e.currentTarget) return;
      remove($target);
    });
  };

  const show = ($trigger) => {
    // freeze html to prevent Scrolling
    freeze.start();
    // show lightbox
    $body.append(config.template.replace('{{url}}', $trigger.attr('href')));
    // handle remove
    const $target = $(config.target);
    handleHide($target);
    // remove loading animations
    const $player = $(config.player);
    $player.on('load', () => {
      $player.fadeIn(200);
      $target.removeClass(config.loadingCN);
    });
  };

  const build = (trigger) => {
    const $trigger = $(trigger);

    $trigger.on('click', (e) => {
      e.preventDefault();
      show($trigger);
    });
  };

  const setup = () => {
    $(config.trigger).each((i, trigger) => build(trigger));
  };

  const init = () => {
    setup();
  };

  return {
    init };
})();

export default video.init();
